import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useRef, useState } from "react";
import {
  addEmployer,
  adminSignOut,
  auth,
  checkPermissions,
  listenToEmployers,
  signInWithGooglePopup,
} from "../services/firebase";
import { useTranslation } from "react-i18next";
import Logo from "../components/Logo";
import Nav from "../components/Nav";
import { getTypeFormResponseProxy } from "../services/firebase";
function AdminApp() {
  const [employers, setEmployers] = useState({});
  const [user, setUser] = useState();
  const [userIsVerified, setUserIsVerified] = useState();
  const employerNameInput = useRef();
  const employersListener = useRef();
  const logGoogleUser = async () => {
    const response = await signInWithGooglePopup();
    console.log(response);
  };
  const inviteCodeInput = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    onAuthStateChanged(auth, (userUpdate) => {
      if (userUpdate && userUpdate !== user) {
        setUser(userUpdate);
        checkPermissions(userUpdate.uid).then((result) => {
          setUserIsVerified(result);
        });
        employersListener.current = listenToEmployers(setEmployers);
      } else {
        if (employersListener.current) {
          employersListener.current.off();
        }
        setEmployers({});
        setUser(null);
      }
    });

    return employersListener.current && employersListener.current.off();
  }, []);

  function addNewEmployer(e) {
    e.preventDefault();
    const employerName = employerNameInput.current.value;
    const inviteCode = inviteCodeInput.current.value;

    if (employers) {
      if (Object.keys(employers).includes(employerName)) {
        alert(t("pages:admin.error.companyExists"));
        return;
      }

      if (Object.values(employers).includes(inviteCode)) {
        alert(t("pages:admin.error.inviteCodeExists"));
        return;
      }
    }
    addEmployer(employerName, inviteCode);
  }

  return (
    <>
      <div className="Header">
        <Logo />
        <Nav />
      </div>
      <div className="Main">
        <div className="AdminPage">
          {!user && <button onClick={logGoogleUser}>Sign In With Google</button>}
          {user && (
            <div>
              <h1>
                Admin
                <button className="btn outline" onClick={adminSignOut}>{t("pages:admin.signOut")}</button>
              </h1>

              {!userIsVerified && <div>{t("pages:admin.error.unverifiedAdminUser")}</div>}
              {userIsVerified && (
                <div>
                <button onClick={() => getTypeFormResponseProxy("443xxlln3busn0egb443xxlc2axp8hvg","H3MiP5Nr")}>TEST</button>
                  <form className="AdminPage--form" onSubmit={addNewEmployer}>
                    <legend>{t("pages:admin.form.createEmployer")}</legend>
                    <fieldset>
                      <label>{t("pages:admin.form.employerName")}: </label>
                      <input ref={employerNameInput} />
                    </fieldset>
                    <fieldset>
                      <label>{t("pages:admin.form.uniqueInviteCode")}: </label>
                      <input
                        minLength="5"
                        ref={inviteCodeInput}
                        placeholder="Eg. 'kal-j2l-9p3' or 'Julius-Baer-2023'"
                      />
                    </fieldset>
                    <fieldset>
                      <button onClick={addNewEmployer}>{t("pages:admin.form.addCTA")}</button>
                    </fieldset>
                  </form>
                  <div className="AdminPage--result">
                    <h2>{t("pages:admin.form.existingCode")}</h2>
                    <table>
                      <thead>
                        <th>{t("pages:admin.form.employerName")}</th>
                        <th>{t("pages:admin.form.uniqueInviteCode")}</th>
                      </thead>
                      <tbody>
                        {employers &&
                          Object.entries(employers).map(([name, { dirtyInviteCode }]) => (
                            <tr>
                              <td>{name}</td>
                              <td>{dirtyInviteCode}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default AdminApp;
