import { useState } from "react";
import i18n from "../i18n";
import LanguageSwitch from "./LanguageSwitcher";
import { NavLink, useNavigate, useLocation } from "react-router-dom";

import logoUSG from "../img/logo-usg-cdi.svg";
import logoEnableMe from "../img/logo-enableme.svg";
import { useTranslation } from "react-i18next";

const languages = {
  en: "English",
  de: "Deutsch",
};

const Nav = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [language, setLanguage] = useState(i18n.language);

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    const [currentLanguage, ...path] = location.pathname.slice(1).split("/");

    if (currentLanguage in languages) {
      navigate(
        {
          ...location,
          pathname: `/${[lang, ...path].join("/")}`,
        },
        { replace: true },
      );
    }
  };

  return (
    <>
      <nav className="MainNav">
        <ul>
          <li>
            <NavLink to={`/${language}/employees`}>{t("navigation.forEmployees")}</NavLink>
          </li>
          <li>
            <NavLink to={`/${language}/companies`}>{t("navigation.forCompanies")}</NavLink>
          </li>
          <li>
            <NavLink to={`/${language}/partner`}>{t("navigation.partner")}</NavLink>
          </li>
          <li>
            <NavLink to={`/${language}/about`}>{t("navigation.about")}</NavLink>
          </li>
          <li>
            <LanguageSwitch language={language} passLanguageChange={handleLanguageChange} />
          </li>
        </ul>
      </nav>

      <div className="PartnerLogos">
        <a
          title="University of St. Gallen - Center for Disability and Integration"
          href="https://cdi.unisg.ch/de"
          target="_blank"
        >
          <img
            alt="University of St. Gallen - Center for Disability and Integration"
            src={logoUSG}
          />
        </a>
        <a title="EnableMe - Together we grow" href="https://www.enableme.de" target="_blank">
          <img alt="EnableMe - Together we grow" src={logoEnableMe} />
        </a>
      </div>
    </>
  );
};

export default Nav;
