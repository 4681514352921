import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  connectAuthEmulator,
} from "firebase/auth";
import {
  child,
  get,
  set,
  getDatabase,
  ref,
  onValue,
  connectDatabaseEmulator,
} from "firebase/database";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import sanitizeInviteCode from "./sanitizeInviteCode";
import { dataVariable } from "../calculation";
import { FormId, DirtyInviteCode, ResponseId, SanitisedInviteCode, EmployerName } from "../types";

const firebaseConfig = {
  apiKey: "AIzaSyAiUq97YonD44GqqIRTSIp-saMW5b-Rx8Q",
  authDomain: "nmb-enableme-prototype.firebaseapp.com",
  databaseURL: "https://nmb-enableme-prototype-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "nmb-enableme-prototype",
  storageBucket: "nmb-enableme-prototype.appspot.com",
  messagingSenderId: "489207467835",
  appId: "1:489207467835:web:71cf0db0180aa4ed48f11e",
  measurementId: "G-8TLPPSW72M",
} as const;

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
// Initialize Firebase Auth provider
const provider = new GoogleAuthProvider();

// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({
  prompt: "select_account ",
});

const db = getDatabase();
export const auth = getAuth();
const functions = getFunctions(firebaseApp);

// This section is mentioned in README.md: Ports, so remember to update those docs if anything changes here
// eslint-disable-next-line no-restricted-globals
if (location.hostname === "localhost") {
  connectDatabaseEmulator(db, "127.0.0.1", 9000);
  connectAuthEmulator(auth, "http://127.0.0.1:9099"); // note protocol and port is included in the address here
  connectFunctionsEmulator(functions, "localhost", 5001);
}

const dbRef = ref(db);

export function listenToEmployers(cb) {
  const employersRef = ref(db, "employers");
  onValue(employersRef, (snapshot) => {
    const data = snapshot.val();
    cb(data);
  });
}

export async function addEmployer(employer: EmployerName, dirtyInviteCode: DirtyInviteCode) {
  const inviteCode = sanitizeInviteCode(dirtyInviteCode);
  await set(ref(db, `employers/${employer}`), {dirtyInviteCode, inviteCode});
  await set(ref(db, `inviteCodes/${inviteCode}`), {employer});
}

export async function verifyInviteCode(dirtyInviteCode: DirtyInviteCode) {
  const inviteCode = sanitizeInviteCode(dirtyInviteCode);
  return get(child(dbRef, `inviteCodes/${inviteCode}`))
    .then((snapshot) => {
      return snapshot.val();
    })
    .catch((e) => {
      throw e;
    });
}

interface ResultMeta {
  formId?: FormId;
}
export async function saveResult(
  dirtyInviteCode: DirtyInviteCode | SanitisedInviteCode,
  responseId: ResponseId,
  meta: ResultMeta,
) {
  const inviteCode = sanitizeInviteCode(dirtyInviteCode);
  return set(ref(db, `results/${inviteCode}/${responseId}`), {
    timestamp: new Date().getTime(),
    ...meta,
  });
}

export async function checkPermissions(uid) {
  return get(child(dbRef, `admins/${uid}`))
    .then((snapshot) => {
      return snapshot.val();
    })
    .catch((e) => {
      throw e;
    });
}

export async function adminSignOut() {
  signOut(auth)
    .then(() => {
      // Sign-out successful.
    })
    .catch((error) => {
      // An error happened.
    });
}

interface typeformResponse {
  variables: dataVariable[];
}

export async function getTypeFormResponseProxy(responseId: ResponseId, formId: FormId) {
  const response = httpsCallable(functions, "getTypeFormResponse");
  return response({ responseId, formId }).then((result) => {
    return result.data as typeformResponse;
  });
}
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
