import Footer from "../components/Footer";
import Logo from "../components/Logo";
import Nav from "../components/Nav";
import ebgbLogo from "../img/ebgb_d_cymk_neg_hoch.png";
import cdiLogo from "../img/logo-usg-cdi.svg";
import aiLogo from "../img/logo_arbeitsleben_inklusiv.png";
import { useTranslation } from "react-i18next";
const Partner = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="Header">
        <Logo />
        <Nav />
      </div>
      <div className="Main">
          <section className="SimplePage PartnerPage">
              <img src={cdiLogo} alt={t("pages:partner.cdi.logoAlt")}/>
              <p
                  dangerouslySetInnerHTML={{
                      __html: t("pages:partner.cdi.description", {
                          interpolation: {escapeValue: false},
                      }),
                  }}
              />
              <hr/>
              <img src={aiLogo} alt={t("pages:partner.ai.logoAlt")}/>
              <p
                  dangerouslySetInnerHTML={{
                      __html: t("pages:partner.ai.description", {
                          interpolation: {escapeValue: false},
                      }),
                  }}
              />
              <p
                  dangerouslySetInnerHTML={{
                      __html: t("pages:partner.ai.description2", {
                          interpolation: {escapeValue: false},
                      }),
                  }}
              />
              <hr/>
              <img src={ebgbLogo} alt={t("pages:partner.ebgb.logoAlt")}/>
              <p
                  dangerouslySetInnerHTML={{
                      __html: t("pages:partner.ebgb.description", {
                          interpolation: {escapeValue: false},
                      }),
                  }}
              />
          </section>
      </div>
        <Footer/>
    </>
  );
};
export default Partner;
